@import '../../styles/customMediaQueries.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LocationAutocompleteInput_inputHeight: 50px;
  --LocationAutocompleteInput_sidePadding: 24px;
  --LocationAutocompleteInput_sidePaddingDesktop: 36px;
}

.root {
  position: relative;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.closeWrapper {
  position: relative;
  cursor: pointer;
  width: 45px;
  border-radius: 0 30px 30px 0;

  &:hover {
    background-color: var(--marketplaceColor);
  }
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 10px;
  height: 10px;
}