@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
}
.title-highlight {
  color: green;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 33px;
  }
}

a,
.a:hover{
    text-decoration: none !important;
}

.content {
  width: 100%;
  margin-top: 25px;

  &:hover{
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-right: 30px;
    margin-bottom: 10px;
    flex-basis: calc(33.33% - 20px);

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 11px;
  object-fit: cover;
  object-position: center;
}
.linkText {
  composes: h2 from global;
  /* padding-left: 20px; */
  font-size: 18px;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.text {
  color:black;
  font-weight: 700;
}
.benefitsSubTitle2{
  color: red;
}

.subTitle1{
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
      white-space: pre-line;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

.subTitle{
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}
/* .subText,
.subTitle {
  white-space: pre-line;
  color: var(--matterColor);
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
} */

.subText {
  font-size: 13px;
}
.subText span{
  /* padding-left: 20px; */
  font-size: 18px;
}
.showOriginalImage {
  object-fit: contain;
}

.showVideoThumbnail {
  object-fit: unset;
}

.cover {
  object-fit: cover;
}
.nameText{
  font-size: 20px;
  line-height: 36px;
}
