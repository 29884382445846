/* Custom properties */
:root {
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.tooltip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--matterColorLight);
  background: var(--matterColorDark);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;

  /* CSS border triangles */
  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  /* Absolute positioning */
  &.top {
    top: calc(var(--tooltip-margin) * -1);

    &::before {
      top: 100%;
      border-top-color: var(--matterColorDark);
    }
  }

  /* Absolute positioning */
  &.right {
    left: calc(50% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);

    /* CSS border triangles */
    &::before {
      left: calc(var(--tooltip-arrow-size) * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: var(--matterColorDark);
    }
  }

  /* Absolute positioning */
  &.bottom {
    bottom: calc(var(--tooltip-margin) * -1);

    /* CSS border triangles */
    &::before {
      bottom: 100%;
      border-bottom-color: var(--matterColorDark);
    }
  }

  /* Absolute positioning */
  &.left {
    left: auto;
    right: calc(50% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);

    /* CSS border triangles */
    &::before {
      left: auto;
      right: calc(var(--tooltip-arrow-size) * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: var(--matterColorDark);
    }
  }
}
