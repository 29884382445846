@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;
  max-width: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 20px;
  }

  @media (--viewportLarge) {
    flex-basis: calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {

  @media (--viewportMedium) {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    margin-right: 20px;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
