@import '../../styles/customMediaQueries.css';

.logo {
  display: flex;
  justify-content: center;
  margin: 30px 0 50px;

  @media (--viewportMedium) {
    margin-bottom: 70px;
  }
}

.logo svg {
  transform: scale(1.4);

  @media (--viewportMedium) {
    transform: scale(1.7);
  }
}

.heading {
  text-align: center;
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  color: var(--textColorAntiDark);
}

.title {
  margin: 0;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorColorful);
}

.subTitle {
  margin-bottom: 0;
}

.mainSection,
.subSection {
  & p {
    font-size: 14px;
    line-height: normal;
  }

  & ul {
    margin-top: 0;
    list-style: disc;
    padding-left: 40px;
  }

  & li {
    font-size: 14px;
    line-height: normal;
  }

  & ol li {
    padding-left: 10px;
  }
}

.mainSection .title {
  font-size: 16px;
}

ol.listNoMargin {
  margin-top: -15px;
  margin-bottom: -15px;
}

.paddingLeft {
  padding-left: 50px;
}