@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  position: relative;
  padding: 15px;

  &::before {
    background: var(--matterColorBackgroundAnti) 0% 0% no-repeat padding-box;
    position: absolute;
    content: '';
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.sectionContainer {
  max-width: 1128px;
  padding: 50px 36px 60px;
  margin: 30px auto 30px auto;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.sectionContainer h2 {
  font-size: 28px;
  line-height: 1.2;
}

.titleColumn {
  flex: 50% 0 0;
}

.buttonColumn {
  flex: 50% 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 15px;
}

@media (max-width: 768px) {

  /* .heading_text h1 {
    font-size: 22px;
    line-height: 11px;
  } */
  .image {
    display: none;
  }

  .heading_text p {
    line-height: 1;
    font-size: 13px;
  }

  .btn {
    width: 100%;
  }
}

@media(max-width:1023px) {
  .heading_text h1 {
    font-size: 22px;
    line-height: 11px;
  }

  .image img {

    aspect-ratio: 5 / 4;
  }

  .modalContent {
    width: 50% !important;
  }

  .modalOverlay {
    top: 22px !important;
  }
}

@media(max-width:600px) {
  .btn {
    margin-top: 0px;

  }

  .heading_text h1 {
    font-size: 16px;
    line-height: 0px;
  }
}


.link {
  &:hover {
    text-decoration: none;
  }
}

.button {
  color: var(--matterColorLight);
  width: 250px;
  position: relative;
  z-index: 1;
  box-shadow: var(--boxShadowButton);
}

.heading_text h1 {
  font-weight: 600;
  line-height: 32px;
}

.heading_text p {
  line-height: 5px;
  font-weight: 100;
  font-size: 14px;
}

.image {
  flex: 1.3;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  /* height: 100%;
  width: 50%; */


}

.image img {


  max-inline-size: 100%;
  block-size: auto; 
  aspect-ratio: 7 / 6;
  position: relative;
  top:70px
}

.heroContentN {
  position: relative;
  z-index: 1;

  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    /* padding-bottom: 10px; */
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding-right: 36px;
    padding-left: 36px;
  }

  @media only screen and (min-width: 1367px) {
    width: 100%;
    padding-right: 36px;
    padding-left: 36px;
  }
}

.rootN {
  /* margin-top: 70px !important; */
  padding-top:70px !important;
  padding-bottom: 70px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  background: url('../../assets/CTA-B\ \(2\).png') no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;

  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-origin: content-box;
    background-size: 100%;
    z-index: 0;

    @media (--viewportMedium) {
      /* background-size: 50%; */
      background-size: 100%;
    }

    @media (--viewportLarge) {
      background-size: 100%;
      /* padding-right: 45px; */
    }
  }

  /* Text positioning is done with paddings */
}

.containera {
  display: flex;
  padding: 0 20px;
  position: relative;
  color: #ffffff;
  max-width: 1128px;
  margin: 0px auto 0px auto;
}

.content {
  /* flex: 1; */
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

}

@media (max-width: 768px) {

  /* .heading_text h1 {
    font-size: 22px;
    line-height: 11px;
  } */
  .image {
    display: none;
  }

  .heading_text p {
    line-height: 1;
    font-size: 13px;
  }

  .btn {
    width: 100%;
  }
}

@media(max-width:1023px) {
  .heading_text h1 {
    font-size: 22px;
    line-height: 11px;
  }

  .image img {

    aspect-ratio: 5 / 4;
  }

  .modalContent {
    width: 50% !important;
  }

  .modalOverlay {
    top: 22px !important;
  }
}

@media(max-width:600px) {
  .btn {
    margin-top: 0px;

  }

  .heading_text h1 {
    font-size: 20px;
    line-height: 9px;
  }
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.GetButton {
  border: none;
  height: 47px;
  width: 164px;
  background-color: #FC3E44;
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 700;
  cursor: pointer;
  transition: all 150ms ease-out;
}

/* .GetButton:hover {
  box-shadow: 8px 9px 6px 1px #00000096;
  transition: all 150ms ease-out;
} */

.GetButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}


.GetButton:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}

.GetCoachButton {
  color: #ffffff;
  font-weight: 700;
  width: 100%;
}

.heading_text h1 {
  font-size: clamp(20px, 3vw, 38px);
  line-height: 33px;
}

.heading_text p {
  font-size: 19px;
  line-height: 14px;
}

.IconBoldly {
  position: relative;
  height: 40px;
  top: -38px;
  /* left: -8px; */
  height: 35px;
  width: 120px;
  top: 6%;
}