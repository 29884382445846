/* .contentListed{
    border: 1px solid rgba(0, 0, 0, 0.093);
    border-radius: 5px;
    } */
    .textAdd{
        font-weight: 600 !important;
        color: #7C9BFF;
    }
    .lineTexts{
        text-align: start !important;
        padding-left: 9px;
        font-size: 16px;
        line-height: 17px;

    }
    .aspectW img{
        position: absolute;
        top: 6px;
        bottom: 0;
        left: 9px;
        right: 0;
        width: 95%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    .subTexts{
        text-align: start !important;
        padding-left: 12px;
        font-size: 13px;
    }
    .text span{
        font-size: small !important;
    }

    .subTitle{
        font-size: 25px;
        font-weight: 400;
        line-height: 36px;
    }