@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  composes: h4 from global;
  margin-left: 20px;
  padding: 16px 0 30px;
  border: none;
}

.iconGlass {
  display: none;
}

.locationLabel {
  margin-bottom: 16px;
  padding-top: 8px;
  font-weight: var(--fontWeightSemiBold);
}

.locationInput {
  padding-left: 0;
  padding-bottom: 5px;
  line-height: 32px;
  border-color: var(--attentionColor);
}

.locationFilterPlain {
  border-bottom: 1px solid var(--matterColorNegative);
}

.labelForPlain {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locationFilterForm {
  margin-bottom: 50px;
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.isHideLocationForm {
  display: none;
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.filterLabel,
.filterLabelSelected {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.buttonWrapper {
  padding-top: 24px;
}

.shouldAddPadding {
  padding-bottom: 31px;
}

.clearButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}