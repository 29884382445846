@import '../../styles/customMediaQueries.css';

.coachCard {
  position: relative;
  background: var(--matterColorLight);
  border: 1px solid var(--edeF5GreyColor);
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  display: grid;
  border-radius: 6px;
  min-height: 400px;
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    padding: 24px;
    border-radius: 16px;
    flex-direction: row;
    grid-template-columns: 200px auto;
    gap: 16px;
    min-height: 300px;
  }

  @media (--viewportLarge) {
    grid-template-columns: 200px auto 252px;
    min-height: unset;
  }

  @media (--viewportSLarge) {
    gap: 32px;
  }
}

.rootForImage {
  width: 100%;
  border-radius: 6px;
  min-height: 250px;
  grid-column: 1/3;
  height: auto;

  @media (--viewportMedium) {
    border-radius: 16px;
    height: 200px;
    min-height: unset;
    grid-column: 1/2;
  }
}

.heading {
  display: flex;
  align-items: center;
}

.info {
  font-size: 14px;
  line-height: 21px;
  grid-column: 1/3;

  & > *:last-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  @media (--viewportMedium) {
    grid-column: 2/3;
  }
}

.title {
  font-weight: var(--fontWeightSemiBold);
  color: var(--dark21Color);

  @media (--viewportMedium) {
    margin-top: 0;
    font-size: 24px;
    line-height: 36px;
  }

  & h3 {
    font-size: inherit;
  }
}

.chemistryButton {
  margin-top: 30px;
  font-size: 14px;
  min-width: 252px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  @media (--viewportLarge) {
    height: 48px;
  }
}

.buttonLayout {
  max-width: 252px;
  grid-column: 1/3;

  @media (--viewportMedium) {
    display: flex;
    grid-column: 2/3;
    width: 100%;
    margin-left: auto;
  }

  @media (--viewportLarge) {
    align-items: center;
    grid-column: 3/4;
    grid-row: 1/2;
  }
}

.closeIcon {
  position: absolute;
  right: -16px;
  top: -13px;

  &:hover {
    cursor: pointer;
  }
}

.disable {
  &:hover {
    cursor: default;
  }
}

.disabledButton {
  background: var(--disabledColor);
}

.iconClassName {
  background: unset;

  & svg {
    display: none;
  }
}

.skeleton {
  @media (--viewportMedium) {
    grid-column: 1/3;
  }
  @media (--viewportLarge) {
    grid-column: 1/4;
    min-height: 250px;
  }
}

.modalTitle {
  margin-top: 30px;
  font-size: 32px;
  line-height: 48px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--dark21Color);
}

.avatarText {
  font-size: 50px;
}

.invitedLabel,
.invitedLabelOnMobile {
  display: none;
  background: var(--green6eColor);
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  color: var(--matterColorLight);
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  padding: 6px 12px;
  grid-column: 2/3;

  @media (--viewportLarge) {
    display: block;
    grid-column: 3/4;
    grid-row: 1/2;
  }
}

.invitedLabelOnMobile {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.modal {
  @media (--viewportMedium) {
    flex-basis: 600px !important;
  }
}

.bookChemistryTooltipContent {
  @media (--viewportMedium) {
    right: 90% !important;
  }
}

.icon1{
  width: 20px;
  height: 20px;
}