@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 50px 30px;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1180px;
  }
  
  @media (--viewportMLarge) {
    max-width: 1140px;
  }

  @media (--viewportSLarge) {
    max-width: 1180px;
    padding: 0;
  }

  @media (--viewportXLarge) {
    max-width: 1540px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.someLinks {
  display: flex;
  justify-content: flex-end;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  column-gap: 40px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex: 1.75;
  flex-basis: 100%;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
    flex-basis: 0;
  }
  @media (--viewportLarge) {
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
}

.organizationCopyright {
  margin-top: 24px;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex: 1;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listItem span {
  font-size: 16px;
  letter-spacing: -0.02em;
  color: var(--footerListItemColor);
  font-weight: 500;
  line-height: 1.2;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex: 1;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy {
  /* Font */
  color: var(--footerLegalLinkColor);
  letter-spacing: -0.02em;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

div.link {
  @media (--viewportMedium) {
    line-height: 32px;
  }
}

.listItemHeading {
  color: #131315;
  font-size: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 12px;
}

.listItemHeading span {
  font-size: 18px;
}

.listItemSubHeading {
  composes: listItemHeading;
  margin-top: 6px;
}

.listItemSubHeading span {
  font-size: 16px;
}

.organizationInfoParagraph {
  color: var(--matterColorDark);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  line-height: 1.5;
  margin-bottom: 20px;
}

.seperateLineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--footerSeperateLineColor);
}

.dot {
  min-height: 4px;
  min-width: 4px;
  background-color: var(--footerSeperateDotColor);
}

.footerBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  @media (--viewportMedium) {
    flex-direction: row;
    column-gap: 40px;
    justify-content: space-between;
  }
}

.rightFooterBottom {
  font-size: 14px;
  color: var(--footerRightBottomColor);
  @media (--viewportMedium) {
    order: 1;
  }
}

.middleFooterBottom {
  display: flex;
  justify-content: center;
  @media (--viewportMedium) {
    order: 2;
  }
}

.leftFooterBottom {
  @media (--viewportMedium) {
    order: 3;
  }
  & a {
    &:hover {
      background-color: var(--footerLinkHoverColor);
    }
    display: inline-block;
    background-color: var(--marketplaceColor);
    border: 1px solid var(--footerLinkedInBorderColor);
    border-radius: 5px;
    transition: color 0.3s;
    padding: 8px;
    & img {
      display: block;
    }
  }
}

.companyName {
  color: var(--footerRightBottomCompanyNameColor);
}