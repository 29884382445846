@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.panelTitle {
  margin-bottom: 0px;

  @media (--viewportLarge) {
    padding: 1px 0 7px 0;
  }
}

.howItWorks {
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.processImage{
  width: 100%;
  height: auto;
}
