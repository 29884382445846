@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
}

.container,
.secondContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  mix-blend-mode: luminosity;
  background-color: none;
  align-items: center;
  padding-top: 20px;

  @media (--viewportMedium) {
    justify-content: space-between;
    padding: 20px 0;
  }
}

.secondContainer {
  padding: 0;

  @media (--viewportMedium) {
    margin-top: -25px;
  }
}
.sapLogo,
.sapLogoSpace {
  height: 40px;

  @media (--viewportMedium) {
    width: 313px;
    height: 100%;
    object-fit: fill;
    mix-blend-mode: color-burn;
    aspect-ratio: 4 / 1;
  }
}

.auraLogo {
  height: 40px;

  @media (--viewportMedium) {
    width: 313px;
    /* height: 20%; */
    object-fit: fill;
    mix-blend-mode: color-burn;
    aspect-ratio: 4 / 1;
  }
}


.horganLogo {
  height: 20px;

  @media (--viewportMedium) {
    width: 313px;
    height: auto;
    object-fit: fill;
    mix-blend-mode: color-burn;
    aspect-ratio: 4 / 1;
  }
}

.hrtechLogo,
.hrtechLogoSpace {
  height: 40px;

  @media (--viewportMedium) {
    width: 313px;
    height: auto;
    object-fit: cover;
    mix-blend-mode: color-burn;
    aspect-ratio: 3 / 1;
  }
}

.sapLogoSpace {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.hrtechLogoSpace {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
.hrtechLogo{
  aspect-ratio: 4 / 1;

}
.fitbotsLogo {
  height: 40px;

  @media (--viewportMedium) {
    width: 309px;
    height: auto;
    position: relative;
    left: 14px;
  }
}

.getAheadLeadershipLogo {
  height: 80px;

  @media (--viewportMedium) {
    width: 190px;
    height: auto;
    position: relative;
    left: 16px;
  }
}
.SectionOurPartnershipssubTitle{
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

/* .SectionOurPartnerships {
  display: flex;
  gap: 10px;
  font-size: 44px;
  font-weight: 700;
} */
.SectionOurPartnerships {
  display: flex;
  gap: 10px;
  font-size: 44px;
  font-weight: 700;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}
.SectionOurPartnerships h2{
 margin-bottom: 0px;
}
/* .sectionPartnerships{
  color: #FC3E44;
  font-size: 44px;
  font-weight: 700;
} */
.sectionPartnerships{
  margin-top: 0px;
  color: #FC3E44;
  font-size: 44px;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}
/* .SectionOurPartnershipstitle{
  font-size: 44px;
  font-weight: 700;
} */
.SectionOurPartnershipstitle{
  font-size: 44px;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0px !important;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}