@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.firstName,
.familyName,
.roleTitle,
.emailCoachee,
.reportingLine {
  margin-bottom: 40px;
}

.reportingLine {
  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.rowWrapper {

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
  margin-bottom: 0;
}

.dropdownField {
  margin-bottom: 40px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.emailTooltip {
  width: 100%;
  top: 0;
}

.emailTooltipContent {
  padding: 8px !important;
  line-height: 1.2 !important;
  white-space: wrap !important;
  height: fit-content;
  transform: translateX(-100%) translateY(calc(-50% - 40px)) !important;

  @media (--viewportMedium) {
    transform: translateX(calc(-100% + 20px)) translateY(calc(-50% - 50px)) !important;
  }
}