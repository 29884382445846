.root {
  fill: var(--matterColorNegative);
}

.filled {
  fill: var(--matterColorSecondForAvatar);
}

.halfStar {
  clip-path: polygon(0 1%, 50% 0, 50% 100%, 0% 100%);
}
