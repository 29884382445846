@import '../../styles/customMediaQueries.css';

.root {
  /* background: linear-gradient(180deg, var(--matterColorRed) 0%, var(--matterColorBackgroundAnti) 100%); */
  background-color: #EFF3FF;
  padding: 70px 0;
  width: 100%;
}
.customroot{
  /* margin-top: 70px !important; */
}
/* .title span{
  color: #FC3E44;
  font-weight: 700;
  font-size: 44px;
} */
.title span{
  color: #FC3E44;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);
  font-size: 44px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}
/* .title{
  font-weight: 700;
  font-size: 44px;
} */
.title{
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);
  font-size: 44px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.StarRateing{
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.Mainsubtitle{
text-align: center;
  gap: 20px;
  font-size: 21px;
  font-weight: 700;
  justify-content: space-between;
  color: black;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 34px;

  @media (--viewportLarge) {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
@media(max-width:717px){
  .Mainsubtitle{justify-content: center;}
}
.title {
  composes: h1 from global;
text-align: center;
  /* color:black; */
  margin-top: 0;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportLarge) {
    max-width: 992px;
    padding: 0 36px 0 36px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.video {
  display: flex;
  justify-content: center;
}

.paddingForVimeoVideo {
  padding: 0;
}

.vimeoVideo {
  position: relative;
  width: 100%;
  padding: 55% 0 0 0;
  position: relative;

  @media (--viewportMedium) {
    width: 810px;
    height: 453px;
  }
}
.Star img{
  width: 30px;
  height: 30px;
  gap: 20px;
}
iframe{
  border-radius: 16px;
}
.vimeoVideo iframe,
.vimeoVideo object,
.vimeoVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.introductionVideo {
  padding: 0 30px;
  width:1060px;
  height: 200px;

  @media (--viewportSmall) {
    max-width: 450px;
  }

  @media (--viewportMedium) {
    padding: 0;
    width: 610px;
    height: 348px;
    max-width: unset;
  }

  @media (--viewportLarge) {
    width: 1060px;
    height: 453px;
  }
}
.subtitle5 div{
  color: black;
  padding: 0px;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

.subTitle {
  white-space: pre-line;
  color: var(--matterColorLight);

  margin: 0 24px 32px 24px;

  @media (--viewportLarge) {
    max-width: 992px;
    padding: 0 36px 0 36px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.star1{
  flex:1 0 140px;
}
.star2{
  flex:1 0 120px;
}
.star3{
  flex:1 0 60px;
}

.hide {
  display: none;
}