@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  border-radius: 5px;
  text-align: left;
  min-height: unset;
  height: 45px;

  @media (--viewportLarge) {
    margin-top: 0px;
    height: 65px;
    font-size: 18px;
  }
}

.icon {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  margin-right: 13px;
  background-color: var(--marketplaceColorDark);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
