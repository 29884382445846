@import '../../styles/customMediaQueries.css';

.root {}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.section {
  /* margin-top: 70px !important; */
}

.SectionLocationFreeCoaching {
  /* margin-top: 70px !important;
  padding-top: 0px !important; */
}

.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  /* height: 67.5vh; */
  max-height: 500px;
  height: 470px;
  padding-top: 50px;

  padding: 0;

  @media (--viewportMedium) {
    /* min-height: 81vh;   */
    /* height: 40.5vh; */
    height: 470px;
    /* max-height: none; */
  }

  @media (--viewportLarge) {
    height: 470px;
  }

  @media (--viewportXLarge) {
    height: 950px;
  }
}

@media (max-width:576px) {
  .heroContainer {
    height: 40.5vh;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;
  border: none;
  border-radius: 0;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectioncontentHowItWork {
  /* margin-bottom: 70px !important; */
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.sectionContent,
.sectionContentWithoutStraightLine,
/* .sectionContentInside, */
.sectionContentLink {
  margin: 0px 24px 0px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    /* margin-bottom: 50px; */
  }



  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0px 36px 0px 36px;
    margin: 0px auto 0px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.coachFont {
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
}


.PaddingCoach {
  padding-top: 70px;
  padding-bottom: 70px;
}

.SectionLocationCoachingCulture {
  padding-top: 0px !important;
}

.sectionOurPartner {
  padding-top: 50px;
  padding-bottom: 25px
}

.sectionContentWithoutStraightLine {
  margin-top: 0;
  margin-bottom: 0;
  /* padding-top: var(--LandingPage_sectionMarginTop); */
  padding-bottom: 70px;
  padding-top: 70px;


  @media (--viewportMedium) {
    /* padding-top: var(--LandingPage_sectionMarginTopMedium); */
  }

  @media (--viewportLarge) {
    /* padding-top: var(--LandingPage_sectionMarginTopLarge); */
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

.section:nth-of-type(2n) .sectionContent::before {

  content: '';
  display: block;
  width: 109px;
  height: 6px;

  position: relative;
  top: -30px;

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
  }
}

.sectionGradientTopBottom {
  background: #EFF3FF;
  width: 100%;
  margin-bottom: 0px;
}

.sectionGradientTopBottomContainer {
  max-width: 1128px;
  padding: 70px 22px 70px;
  margin: 0px auto 0px auto;
  box-sizing: border-box;
}

.textLight {
  font-size: 22px;
  /* font-weight: 200; */
  /* font-weight: var(--fontWeightSemiBold); */
}

.textLight {
  /* font-weight: 400; */
  /* font-weight: var(--fontWeightSemiBold); */
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }

  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

.benefitsSubTitle2 {
  color: #FC3E44;
  margin-bottom: 0px;
}

.sectionGradientTopBottom li {
  list-style-type: disc;
  margin-left: 25px;
}

.redButton {
  background-color: var(--authenticationLinkColor);

  &:hover {
    background-color: var(--matterColorColorfulDark);
  }
}

.greyBackground {
  background: var(--matterColorLight) 0% 0% no-repeat padding-box;
}

.greyBackgrounds {
  /* margin-top: 70px !important; */
  /* padding: 50px 0; */
}

.marginTop {
  margin-top: 30px;
}

.sectionFirstChild {
  /* padding-top: 28px; */
}

.iconSearch {
  transform: rotate(270deg);
  stroke: var(--matterColorLight);
}

.modalLink {
  composes: a from global;
  text-decoration: underline;
}

.modalGetACoachContainer,
.modalContentContainer {
  padding-top: 50px;

  @media var(--viewportMedium) {
    padding: 0;
  }
}

.modalGetACoachContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricingTitle {
  font-size: 22px;
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 20px;

  @media var(--viewportMedium) {
    margin-bottom: 10px;
  }
}

.pricingWrapper {
  color: var(--marketplaceColor);
  display: flex;
  align-items: flex-start;
}

.pricing {
  font-size: 42px;
  font-weight: var(--fontWeightBold);
}

.currency {
  margin: -10px 0 0 10px;
}

.pricingExplanation {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 28px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);
  margin: 10px 0;

  @media var(--viewportMedium) {
    font-size: 34px;
  }
}

.pricingExplanation span {
  display: block;
  margin: 5px 0;
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  font-size: 18px;
  margin-top: 50px;
}

.optional {
  color: var(--textColorAnti);
  font-size: 13px;
  margin-top: 5px;
}

.sectionContentInside {
  /* padding-top: 0; */
  margin: 0px 24px 0px 24px;
  padding-bottom: 70px !important;
  padding-top: 70px !important;

  @media (--viewportMedium) {
    max-width: 100%;

  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0px 36px 0px 36px;
    margin: 0px auto 0px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentLink {
  margin-top: 0;
}

.link {
  color: var(--marketplaceColor);
  border: none;
  font-size: 16px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

.boldFirstLine {
  &:first-line {
    /* font-size: 25px;
    font-weight: var(--fontWeightBold); */
    font-size: 40px;
    font-weight: var(--fontWeightSemiBold);

    margin-top: 0;

    @media (--viewportLarge) {
      margin-top: 15px;
      margin-bottom: 19px;
    }
  }
}

.fitSwiperContainer {
  max-width: 1056px;
}

.carouselItem {
  padding: 0 0 16px;
}

.quote {
  color: var(--matterColorRed);
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
}

.rating {
  display: flex;
  text-align: center;
  gap: 10px;
  justify-content: center;
  margin-top: 16px;

  & svg {
    fill: var(--marketplaceColor);
    width: 11px;
    height: 11px;
  }
}


/* .SectionOnePlatform{
  color: #FC3E44;
  margin-bottom: 0px;
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
} */
.SectionOnePlatform {
  color: #FC3E44;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 44px;
  line-height: 60px;
  font-weight: var(--fontWeightSemiBold);
  /* font-weight: 700; */

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.titleourReviews {
  /* color: #FC3E44; */

  font-size: 44px;
  line-height: 60px;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0px;

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

/* .SectionOnePlatformtitle1{
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
} */
.SectionOnePlatformtitle1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 44px;
  line-height: 60px;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.titlehighlight {
  font-size: 40px;
  line-height: 48px;
  color: #FC3E44;
  font-weight: var(--fontWeightSemiBold)
}

.titlehighlight1 {
  font-size: 44px;
  line-height: 60px;
  /* color: #FC3E44; */
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

/* .titlehighlight1{
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
} */
.titlehighlight1 {
  font-size: 44px;
  line-height: 60px;
  /* font-weight: 700; */

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.sectionHowItWorkAdd {
  background-color: #EFF3FF;
}

.container {
  width: 100%;
  /* margin: 0 auto;
  padding: 0 15px; */
  box-sizing: border-box;
}

/* Styling for the row */
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
  /* padding-right: 7px; */

  /* flex-wrap: wrap; */
  /* margin: -15px; */
}


.col {
  /* padding: 15px; */
  box-sizing: border-box;
}

.colright img {
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 22px;
}

.colleft,
.colright {
  flex: 0 0 50%;
  /* Flex-grow, flex-shrink, and flex-basis values to make each column 50% width */
  max-width: 50%;
  /* Maximum width to ensure columns do not exceed 50% */
}

@media (max-width: 768px) {

  .colleft,
  .colright {
    flex: 0 0 100%;
    /* Full width on smaller screens */
    max-width: 100%;
  }
}

/* .titleH{
  margin-bottom: 3px;
  font-size: 44px;
  line-height: 60px;
  color: #FC3E44;
  font-weight: 700;
  line-height: 60px;
} */

.titleH {
  margin-top: 0px !important;
  margin-bottom: 3px;
  font-size: 44px;
  line-height: 60px;
  color: #FC3E44;
  /* font-weight: 700; */
  line-height: 60px;
  font-weight: var(--fontWeightSemiBold);

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.titleH2 {
  margin-top: 0px;
  font-size: 44px;
  line-height: 60px;
  /* font-weight: 700; */
  line-height: 60px;
  font-weight: var(--fontWeightSemiBold);

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

.GetButton {
  border: none;
  height: 60px;
  width: 202px;
  background-color: #7c9bff;
  border-radius: 30px;
  font-weight: 800;
  font-size: large;
  cursor: pointer;
  transition: all 150ms ease-out;
}

.GetButton:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
  transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px #00000026, 0 10px 30px rgba(0, 0, 0, 0.15);
}


.GetButton:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}

.GetCoachButton {
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
}

.GetCoachButton:hover {
  width: 100%;
  
}



.SectionsPartner {
  background-color: #EFF3FF;
}



.slick-prev:before {
  content: '>' !important;
  color: black;
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.card h4 {
  font-size: 1em;
  margin: 5px 0;
  color: #888;
}

.card p {
  font-size: 0.9em;
  color: #666;
}

.rating {
  margin-top: 10px;
  font-size: 1.2em;
  color: gold;
  justify-content: space-between !important;
}

.brandLogo img {
  max-width: 100px;
  max-height: 70px;
}

.testimonialcard {
  display: flex;
  gap: 20px;
}

.card {
  box-shadow: none !important;
}


.testimoniallogo img {
  border-radius: 50px;
}

.testimonialname {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonialname h3 {
  margin-bottom: 0px;
  font-size: larger;
}

.testimonialname h4 {
  margin: -1px 0;
  font-size: smaller;
}

.ourReviews {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 16px;
}

/* .titleourReview{

  margin-bottom: 3px;
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
  margin-top: 0px !important;
} */
.titleourReview {
  margin-bottom: 3px;
  color: #FC3E44;
  font-size: 44px;
  line-height: 60px;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0px !important;

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    /* font-weight: 600; */
    font-weight: var(--fontWeightSemiBold);
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    /* font-weight: 600px; */
    font-weight: var(--fontWeightSemiBold);
    line-height: 30px;
  }
}

.titleourReview {
  /* color: #FC3E44; */
  font-size: 44px;
  line-height: 60px;
  margin-top: 0px;
  margin-bottom: 0px !important;
  /* font-weight: 700; */
  font-weight: var(--fontWeightSemiBold);

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }

  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}

/* .titleourReviews{
  color: #FC3E44;
  font-size: 44px;
  line-height: 60px;
  margin-top: 0px;
  margin-bottom: 0px !important;
  font-weight: 700;
} */
.containers {
  text-align: center;
  margin-bottom: 30px;
}

.containers p {
  margin: 0px;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }

  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

.ourReviewsseaction {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 25px;
  padding-right: 25px;

  max-width: 1128px;
  /* padding: 70px 22px 70px; */
  margin: 0px auto 0px auto;
  box-sizing: border-box;

}

.customArrow p {
  font-size: 40px;
  position: absolute;
  top: 38% !important;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
  box-shadow: none;
  width: auto;
  height: auto;
  padding: 0;
  font-weight: 600;
}

.customArrow:hover {
  color: #5a7bff;
}

.customNextArrow p {
  right: -45px !important;
}

.customPrevArrow p {
  left: -45px !important;
}

.customArrow p {
  color: #7c9bff !important;
  font-size: x44px;
}

.SectionFreeCoachingResources {
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }

  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}