@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-bottom: 40px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
/* .h11{
  color: red;
  font-size: 44px;
  font-weight: 700;
} */
.h11{
  color: red;
  font-size: 44px;
  font-weight: 700;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
  @media screen and (max-width: 550px) {
    font-size: 24px;
    font-weight: 600px;
    line-height: 30px;
  }
}
.SectionHowItWorkstitleBottom{
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
  }
}

.section{
  display: flex;
  gap: 10px;
}
.section h2{
  margin-bottom: 0px;
}
/* .headingTexts {
  font-size: 44px;
  font-weight: 700;} */
  .headingTexts{
    font-size: 44px;
  font-weight: 700;
    @media screen and (min-width : 550px) and (max-width: 768px) {
      font-size: 32px;
      font-weight: 600;
      line-height: 30px;
    }
    @media screen and (max-width: 550px) {
      font-size: 24px;
      font-weight: 600px;
      line-height: 30px;
    }
  }
  .GetButton{
    border: none;
    height: 60px;
    width: 202px;
    background-color: #7c9bff;
    border-radius: 30px;
    font-weight: 800;
    font-size: large;
    cursor: pointer;
    transition: all 150ms ease-out;
    }
    .GetButton:hover{
      box-shadow: 8px 9px 6px 1px #00000096;
      transition: all 150ms ease-out;
    }
.GetCoachButton{
display: flex;
color: #ffffff;
justify-content: center;
align-items: center;
font-weight: 700;
width: 100%;
}