.root {
  font-size: 14px;
}

.title {
  margin: 0;
  font-weight: var(--fontWeightBold);
}

.agreedTermSection,
.partiesSection {
  & ol {
    counter-reset: item;
    padding-left: 15px;
  }

  & li {
    display: block;
    font-size: 14px;
    margin-bottom: 7px;
    margin-top: 10px;

    &:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }
  }
}

