@import '../../styles/customMediaQueries.css';

.reviewChemistrySessionForm {
  display: grid;
  margin-bottom: 24px;
  background: var(--matterColorLight);
  border: 1px solid var(--edeF5GreyColor);
  padding: 16px;
  border-radius: 6px;
  border: 4px solid transparent;

  @media (--viewportMedium) {
    gap: 16px;
    grid-template-rows: 200px auto;
    grid-template-columns: min-content 200px auto;
  }

  @media (--viewportLarge) {
    gap: 24px;
    padding: 24px;
    grid-template-rows: auto auto;
    grid-template-columns: min-content 256px auto;
  }

  & label {
    padding: 0;
    white-space: nowrap;

    @media (--viewportMedium) {
      font-size: 12px;
    }
  }
}

.avatarText {
  font-size: 50px;
}

.rootForImage {
  width: 100%;
  border-radius: 8px;
  min-height: 250px;
  height: auto;

  @media (--viewportMedium) {
    border-radius: 8px;
    height: 200px;
    width: 200px;
    min-height: unset;
    grid-column: 2/3;
  }

  @media (--viewportLarge) {
    grid-row: 1/2;
  }
}

.title {
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
  }

  @media (--viewportLarge) {
    margin-bottom: 16px;
  }
}

.info {
  @media (--viewportMedium) {
    grid-column: 3/4;
    grid-row: 1/3;
  }
}

.rates {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  & span {
    color: var(--greyC5Color);
    font-size: 12px;
  }

  & .activeLabel {
    color: var(--blue7cColor);
  }

  & .buyerActiveLabel {
    color: var(--matterColorRed);
  }
}

.slider {
  display: block;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    max-width: 400px;
  }

  @media (--viewportLarge) {
    width: 222px;
    margin-bottom: 16px;
  }
}

.addNotesButton,
.saveButton {
  background: var(--matterColorNeutrals);
  border-radius: 4px;
  margin-top: 20px;

  &:hover,
  &:focus {
    background: var(--matterColorNeutrals);
  }

  @media (--viewportMedium) {
    grid-column: 2/3;
  }

  @media (--viewportLarge) {
    grid-column: 3/4;
    grid-row: 3/4;
    margin-left: auto;
  }
}

.addNotesButton,
.cancelButton,
.saveButton {
  @media (--viewportMedium) {
    margin-top: 0;
    width: 100%;
    height: 40px;
    min-height: unset;
  }

  @media (--viewportLarge) {
    width: 163px;
  }
}

.cancelButton {
  margin-top: 20px;
  background: var(--matterColorLight);
  border: none;

  &:hover,
  &:focus {
    background: var(--matterColorLight);
    border: none;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.buttonWrapper {
  display: flex;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    grid-column: 2/3;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    grid-column: 3/4;
    grid-row: 3/4;
    margin-left: auto;
  }
}

.feedbackDisplay {
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: var(--edeF5GreyColor);
  padding: 10px 0 10px 16px;
  min-height: 38px;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-word;

  @media (--viewportMedium) {
    font-size: 12px;
    line-height: 18px;
  }
}

.label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  color: var(--matterColorNeutrals);
  white-space: nowrap;

  @media (--viewportMedium) {
    font-size: 12px;
  }
}

textarea {
  font-size: 14px;

  @media (--viewportMedium) {
    font-size: 12px;
    line-height: 18px;
  }
}

.circleOnMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.circleOnTabletAndDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
    grid-column: 1/2;
    grid-row: 1/2;
    margin: auto;
  }
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid var(--greyA3Color);
  position: relative;
  cursor: pointer;
  padding: 3px;

  @media (--viewportMedium) {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}

.circle.active div {
  background: var(--marketplaceColor);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.heading {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (--viewportMedium) {
    align-items: flex-start;
  }
}

.ratingWrapper {
  @media (--viewportLarge) {
    display: flex;
    max-width: 493px;
    gap: 53px;
  }
}

.confirmLabel {
  color: var(--marketplaceColor);
  border: 2px solid var(--blue80Color);
  border-radius: 32px;
  padding: 0 16px;
  margin-left: auto;
  background: var(--blueF1fColor);
}

.active {
  border: 4px solid var(--marketplaceColor);
}

.buyerSlider {
  & input[type='range'] {
    background-image: linear-gradient(var(--matterColorRed), var(--matterColorRed));
    &::-webkit-slider-thumb {
      background: var(--matterColorRed);
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: baseline;
  gap: 16px;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 16px;
  }
}

.saveButton {
  &:disabled {
    &:hover {
      background-color: var(--matterColorNegative);
    }
  }
}

.submitting {
  &:disabled  {
    background: var(--matterColorNeutrals);

    &:hover {
      background: var(--matterColorNeutrals);
    }
  }
}

.error {
  font-size: 13px;
}

.coacheeFeedback {
  margin-bottom: 15px;
}

.waitingCoacheeReview {
  font-size: 14px;

  @media (--viewportMedium) {
    font-size: 12px;
  }
}

.feedbackLabelGroup {
  display: flex;
  gap: 6px;
  flex-direction: column;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 12px;
  }
}

.note {
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorNeutrals);

  @media (--viewportMedium) {
    font-size: 12px;
    line-height: 16px;
  }
}