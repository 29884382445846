@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.rootP {
  composes: defaultBackgroundImage from global;
  position: relative;
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 83px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/listingHeroMountainOverlay.svg') no-repeat right bottom;
    background-origin: content-box;
    background-size: 80%;
    z-index: 0;

    @media (--viewportMedium) {
      background-size: 50%;
    }

    @media (--viewportLarge) {
      background-size: 40%;
      padding-right: 45px;
    }
  }

  /* Text positioning is done with paddings */
}

.rootN {

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  &::after {
    background: url('../../assets/Boldly BG.png') no-repeat;

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/Boldly BG.png') no-repeat;
    background-origin: content-box;
    background-size: cover;

    background-position: right;
    z-index: 0;

    @media (--viewportMedium) {
      /* background-size: 50%; */
      background-size: cover;
    }

    @media (--viewportLarge) {
      background-size: cover;
      /* padding-right: 45px; */
    }
  }

  /* Text positioning is done with paddings */
}

.backgroundImageForCoach {
  /* background: url('../../assets/background-1440-coach-min.jpeg') no-repeat center; */
  background-size: cover;
}

.backgroundImageForCoachM {
  /* background: url('../../assets/background-coachee.jpg') no-repeat center; */
  background-size: cover;
}


.heroContentN {
  position: relative;
  z-index: 1;

  margin: 0 35px 0 35px;

  @media (--viewportMedium) {
    /* padding-bottom: 10px; */
    /* padding-top: 5px; */
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding-right: 36px;
    padding-left: 36px;
  }

  @media only screen and (min-width: 1367px) {
    width: 100%;
    padding-right: 36px;
    padding-left: 36px;
  }
}

.heroContentP {
  position: relative;
  top: 5px;
  z-index: 1;
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    /* margin: 0 7.5vw 0 7.5vw;
    
    padding-bottom: 10px; */
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    /* padding-right: 36px;
    padding-left: 36px; */

  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px);
    width: 100%;
    /* padding-right: 36px;
    padding-left: 36px; */
  }
}

.spaceTop {
  position: relative;
  top: 13px
}

.heroMainTitle {
  margin-top: 0px;
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;
  font-size: 18px;

  color: var(--matterColorLight);
  margin: 0 0 0 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 0 0;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 300px;
  }
}

.redButton {
  composes: buttonColorsRed from global;
  composes: marketplaceHeroTitleFontStyles from global;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.buttonWrapper {
  display: grid;
  /* grid-gap: 10px;
  gap: 10px; */
  padding: 0 !important;

  @media (--viewportMedium) {
    display: flex;
  }
}

.buttonWrapper .heroButton:nth-of-type(2n) {

  @media (--viewportMedium) {
    margin-left: 20px;
  }
}

.backgroundImageForHongKong {
  background: url('../../assets/Background.jpg') no-repeat center;
  background-size: cover;
}

.titleHongKong {
  display: block;
  composes: animation;
  font-size: 47px;
  font-weight: 700;
  line-height: 56px;
  color: var(--matterColorLight);

}

.subTitleHongKong {
  color: var(--matterColorLight);
  composes: animation;
  font-size: 18px;
  line-height: 1.28571em;
}

.hongKongWrapper {
  padding-top: 30px;
  width: 500px;
}

.heroButtonHongKong {
  composes: button buttonFont buttonText from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 30px;
  }

  border-radius: 18px;
  margin-top: 36px;

}

.whiteButtonHongKong {
  background: #ffffff;
  color: #FC3D44;
  composes: marketplaceHeroTitleFontStyles from global;


}

.backgroundImageForCoachee {
  background: url('../../assets/background-coachee.jpg') no-repeat center;
  background-size: cover;
}

.paddingBottomForCoachee {
  padding-bottom: 130px;

  @media (--viewportMedium) {
    padding-bottom: 80px;
  }
}



.containera {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 18px; */
  /* padding-top:30px;
  padding-bottom:30px; */
  color: #ffffff;
  max-width: 1128px;
  /* margin-left: 100px; */
  /* padding-top: 100px;
  padding-bottom: 70px; */
  margin: 0 auto 0 auto;
}


.content {
  max-width: 60%;
  /* height: 40%; */
}


.image {
  margin-top: 13px;
  max-width: 42%;
  /* Adjust width as necessary */
}

.mentorshipImage {
  max-width: 39%;
}

.image1 {
  margin-top: 13px;
  max-width: 45%;
  /* Adjust width as necessary */
}

.responsiveImage {
  width: 100%;
  /* Make image responsive */
  height: auto;
  /* Maintain aspect ratio */
}

@media (max-width: 579px) {
  .content {
    max-width: 100%;
    /* Make content take full width */
  }

  .btn {
    font-size: 0.9em;
    padding: 8px 8px;
    width: 50% !important;
  }
}

.heading_text {
  position: relative;
  top: 40px;

}

.heading_text h1 {
  margin: 0;
  font-size: 2.7em;
  line-height: 56px;
  font-weight: 700;

  @media screen and (min-width : 768px) and (max-width: 1000px) {
    font-size: 1.7em;
    line-height: 40px;
  }

  @media screen and (min-width : 550px) and (max-width: 768px) {
    font-size: 2.4em;
    font-weight: 700;
    line-height: 40px;
  }

  @media screen and (max-width: 550px) {
    font-size: 2em;
    font-weight: 700px;
    line-height: 40px;
  }
}

/* p, pre, .marketplaceBodyFontStyles{
    margin: 0;
    font-size: 0.5em !important; 
    line-height: 16px !important; 
    display: block;
    @media screen and (min-width : 550px) and (max-width: 768px) {
      font-size: 0.8em;
      font-weight: 600;
      line-height: 16px !important;
    }
    @media screen and (max-width: 550px) {
      font-size: 9px;
      font-weight: 600px;
      line-height: 9px;
    }
  }
} */

.paragraphHeroSection p {
  font-size: 18px;
  line-height: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .paragraphHeroSection p {
    font-size: 11px;
    line-height: 4px;
    margin-top: 16px;
    margin-bottom: 5px;
  }
}

@media (max-width: 550px) {
  .paragraphHeroSection p {
    font-size: 10px;
    line-height: 4px;
  }

  .btn {
    margin-top: 30px !important;
  }
}

@media(max-width:768px) {
  .image img {
    display: none;
  }

  .content {
    max-width: 100%;
    /* Make content take full width */
  }

  .containera {
    margin: 0px 0px 0px 0px !important;
  }

  .btn {
    width: 50% !important;
    margin-top: 40px !important;
  }
}

.btn {
  /* padding: 10px 10px !important;W */
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 50%;
  /* height: 50%; */
  height: 55px;
  font-size: 14px;
  margin-top: 80px;
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
  display: flex;

}

.btn:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
   transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}

.btn:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}

.btnWorkshop:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
   transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}

.btnWorkshop:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}


.btnWorkshop {
  position: relative;
  top: 30px;
}

.btnWorkshop1 {
  position: relative;
  top: 40px;
}

/* .becomeacoachmargin{
  margin-left: 290px;
} */


.btn1 {
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 200px;
  height: 30%;
  font-size: 14px;
  margin-top: 100px;
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
  position: relative;
  top: 70px;
}

.btn1:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
   transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}

.btn1:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}



/* .content {
  flex: 1;
  padding-right: 10px; 
  display: flex;
  align-items: center;
 
} */

/* .image {
  flex: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

} */

/* .image img {
  height: 100%;
  max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: 6 / 6;
  object-fit: cover;
  object-position: bottom;
  position: relative;
  bottom: -51px;
} */

/* .heading_text{
  position: relative;
  top: 50px;
} */
/* .heading_text h1 {
  font-weight: 700;
  line-height: 1.0; 
  font-size: calc(1.1em + 2vw);
  margin: 20px 0; 
} */

/* Media Queries for more precise control */
/* @media (max-width: 1200px) {
  .heading_text h1 {
    font-size: calc(1.1em + 2vw); 
  }
  .heading_text p {
    font-size: calc(0.90em + 0.1vw);
  }
}

@media (max-width: 992px) {
  .heading_text h1 {
    font-size: calc(1.2em + 1.5vw);
  }
  .heading_text p {
    font-size: calc(0.7em + 0.2vw);
  }
} */
/* 
@media (max-width: 768px) {
  .heading_text h1 {
    font-size: calc(1em + 1.0vw);
    margin: 10px 0;  
  }
  .heading_text{
    top: 20px;
  }
  .heading_text p {
    font-size: calc(0.85em + 0.25vw);
    margin: 8px 0px !important;
  }
  .btn{
    width: 100%;
    margin-top: 20px;
  }
  .image img {
    aspect-ratio: 5 / 5;
    bottom: -38px;

}
} */
/* @media (max-width: 576px) {
  .heading_text{
    top: 46px;
  }
  .heading_text h1 {
    font-size: calc(0.6em + 0.9vw);
line-height: 0.7;
  }
  .heading_text p {
    font-size: calc(0.3em + 0.2vw);
    margin: 3px 0px !important;
  }
  .image img {
    aspect-ratio: 5 / 5;
    bottom: -24px;
} */

/* .heading_text p {
  font-weight: 100;
  line-height: 1; 
  font-size: calc(0.em + 0.5vw); 
  margin: 10px 0;
} */
/* 
@media(max-width:1023px){
 .image img {
   
    aspect-ratio: 4 / 4;
    bottom: -20px;

}
}
@media(max-width:767px){
 .image img {
   
    aspect-ratio: 5 / 5;
    bottom: -38px;

}
.modalContent{
  width: 50% !important;
}
.modalOverlay{
  top: 22px !important;
}
.btn{
  margin-top: 8px;
    }
} */



:root {
  --grey: #F5F5F5;
  --dark-grey: #323B40;
  --light-blue: #E0F5FF;
  --blue: #B9E5FE;
  --dark-blue: #00A5FA;
  --green: #B7E0DC;
  --dark-green: #019888;
  --lime: #C7E8C8;
  --dark-lime: #42B045;
  --yellow: #FFEEBA;
  --dark-yellow: #FF9901;
  --pink: #FABAD0;
  --dark-pink: #EF075F;
  --red: #FEC9C6;
  --dark-red: #FD3D08;
}

body {
  font-family: Arial, sans-serif;
}

.closeButton {
  border: none;
  text-align: end;
  margin-right: 1rem;
  font-size: larger;
  border: 1px solid #000000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.modalclose {
  padding: 16px;
  display: flex;
  justify-content: end;
}

.container {
  padding: 2rem 0rem;
  text-align: center;
}

.btnDanger {
  background-color: var(--dark-red);
  color: #FFFFFF;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  width: 32%;
  border-top-left-radius: 12%;
  border-bottom-right-radius: 12%;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--grey);
}

.modalTitle {
  margin-bottom: 0;
  line-height: 1.5;
}

.close {
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.modalBody {
  position: relative;
  padding: 1rem;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid var(--grey);
}

.formGroup {
  margin-bottom: 1rem;
}

.SubmitBtn {
  border: 1px solid #000000;
}

.formControl {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.formText {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.btnSuccess {
  color: #ffffff;
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}

.btnSuccess:hover {
  background-color: var(--green);
}

.panel {
  border-radius: 4px;
  padding: 1rem;
  margin-top: 0.2rem;
  background-color: var(--grey);
  color: var(--dark-grey);
}

.panelBlue {
  background-color: var(--light-blue);
  color: var(--dark-blue);
}

.panelBigHeight {
  min-height: 150px;
}

.item {
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.2rem;
}

.itemBlue {
  background-color: var(--blue);
  color: var(--dark-blue);
}

.itemGreen {
  background-color: var(--green);
  color: var(--dark-green);
}

.itemLime {
  background-color: var(--lime);
  color: var(--dark-lime);
}

.itemYellow {
  background-color: var(--yellow);
  color: var(--dark-yellow);
}

.itemPink {
  background-color: var(--pink);
  color: var(--dark-pink);
}

.itemRed {
  background-color: var(--red);
  color: var(--dark-red);
}

.itemBigWidth {
  min-width: 380px;
}

.modalOverlay {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 104px;
  z-index: 4;

}

.button {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 !important;
  padding:  0 !important;

}

.buttonWhite {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  background: var(--matterColorLight);
  color: var(--HongKongPage_redFont);
  /* margin-top: 42px; */
  font-size: 18px;
  font-weight: 500;
  border-radius: 13px;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;

}

.flexColumn1 {
  /* border: 1px solid black; */
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around; */
  position: relative;
  top: 13px;

}

.coachingTitle {
  margin: 0;
  font-size: 2.7em;
  line-height: 56px;
  font-weight: 700;
}

.coachingSubTitle {
  font-size: 18px;
  line-height: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.responsiveImage1 {
  width: 100%;
  /* Make image responsive */
  /* height: auto; */
}

.coachbtn {
  width: 100%;
  height: max-content;
  display: flex;
  /* border: 2px solid black; */
  /* align-items: baseline; */
}

.coachingbtn {
  /* padding: 10px 10px !important; */
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 95%;
  height: 55px;
  font-size: 14px;
  margin-top: 50px;
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}



.coachingbtn:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
   transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}

.coachingbtn:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}

.workshopBtn {
  /* border:2px solid black */
}

.enquireBtn {
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 100%;
  height: 40%;
  font-size: 14px;
  margin-top: 50px;
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
}

.signUpBtn {
  background-color: #FC3E44;
  color: #ffffff;
}

.coachbutton {
  width: 50%;
}

.btnWorkshop {
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 250px;
  height: 50%;
  font-size: 14px;
  margin-top: 50px;
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
  top: 0px;
}