@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 16px;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.postDate {
  font-size: 13px;
  line-height: 18px;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightRegular);
  margin-top: 5px;
}

.square {
  position: relative;

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: var(--transitionStyleButton);

    &:hover {
      -webkit-transform: scale(1.02);
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 62.7%;
  }
}
