.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.labelAndNote {
  display: flex;
  gap: 6px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 12px;
  }
}
