@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  @media (--viewportLarge) {
    margin-top: 60px;
  }
}

.contentBox {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
}

.item {
  display: flex;
  flex-direction: row;
  min-height: 120px;
  gap: 25px;

  @media (--viewportLarge) {
    flex: 1;
    list-style: none;
    display: inline-block;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--matterColorNegative);
      top: 30px;
      left: 55px;
    }

    &:last-child::after {
      content: none;
    }
  }
}

.title {
  font-size: 15px;
  font-weight: var(--fontWeightBold);
  margin-top: 0;

  @media (--viewportLarge) {
    margin-top: 15px;
    margin-bottom: 19px;
  }
}

.description {
  font-size: 14px;
  line-height: 2;
}

.iconBox {
  background-color: var(--marketplaceColor);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  padding: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 140px;
    width: 2px;
    background-color: var(--matterColorNegative);
    top: 50%;
    z-index: -1;
  }

  @media screen and (max-width: 320px) {
    &::after {
      height: 180px;
    }
  }

  @media (--viewportLarge) {
    &::after {
      content: none;
    }
  }
}

.lastIcon {
  &::after {
    content: none;
  }
}

.icon {
  stroke: var(--matterColorLight);
}

.activeItem {
  background-image: linear-gradient(180deg, var(--matterColorFirstForAvatar) 0%, var(--matterColorSecondForAvatar) 100%);
}