
.contentListed{
/* border: 1px solid rgba(0, 0, 0, 0.093);
border-radius: 11px; */
text-align: center;

}
.textAdd{
    font-weight: 100 !important;
    font-size: medium;
}
.aspectW img{
    position: absolute;
    top: 15px;
    bottom: 0;
    left: 16px;
    right: 0;
    width: 90%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.lineTexts{
    margin: 25px 0px 12px 0px !important;
}

.subTitle{
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
}