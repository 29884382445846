.resendButton {
  border: none;
  outline: none;
  color: var(--marketplaceColor);
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
  margin: 30px auto 0;
  display: block;
}

.error {
  color: var(--failColor);
}

.content {
  margin-top: 30px;
}