@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);
  width: 100%;
  line-height: 18px;
  padding: 8px;

  @media (--viewportMedium) {
    padding: 14px;
  }
}

.root:after {
  content: "";
  position: absolute;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  top: -4px;
  right: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent var(--marketplaceColor) var(--marketplaceColor) transparent;
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
