@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  top: 40%;
  max-width: 610px;
  margin: 0 auto;
}

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: 40px;
  background-color: var(--matterColorLight);
  border-radius: 30px;
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  composes: h1 from global;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  border: none;
  display: flex;
  width: 24px;
  align-self: stretch;
  margin-left: 17px;
  align-items: center;
}

.desktopInput {
  font-size: 14px;
  flex-grow: 1;
  /* Font */
  height: 40px;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}

.keywordSearchWrapper {
  position: relative;
  display: flex;
  background-color: var(--matterColorLight);
  border-radius: 30px;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-left: 20px;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--matterColor);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 3px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.resultBox {
  background-color: var(--matterColorLight);
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: var(--boxShadow);

}

.list {
  margin: 0;
}

.topResultLabel {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 16px 0;
}

.keywordItem {
  border-top: 1px solid var(--matterColorNegative);
  text-align: left;
  padding: 16px 0 16px 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--matterColorNegative);
  }
}

.searchItemText {
  color: var(--marketplaceColor);
  font-size: 14px;
  line-height: 24px;
}

.breadcrumb {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.highlighted {
  background-color: var(--matterColorNegative);
}