@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}


.image {
  /* margin-top: 13px;   */
  max-width: 40%;
  position: relative;
  bottom: 40px;
  /* margin-bottom: 50px; */
  /* height: 70%; */

  /* Adjust width as necessary */
}

.responsiveImage {
  width: 100%;
  /* Make image responsive */
  height: auto;
  /* Maintain aspect ratio */
}



.root {

  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* border: 2px solid black; */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  &::after {
    background: url('../../assets/Boldly BG.png') no-repeat;

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/Boldly BG.png') no-repeat;
    background-origin: content-box;
    background-size: cover;

    background-position: right;
    z-index: 0;

    @media (--viewportMedium) {
      /* background-size: 50%; */
      background-size: cover;
    }

    @media (--viewportLarge) {
      background-size: cover;
      /* padding-right: 45px; */
    }
  }

  /* Text positioning is done with paddings */
}

.rootnnnn {
  /* composes: defaultBackgroundImage from global; */
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/Boldly BG.png') no-repeat;
    /* background: linear-gradient(180deg, var(--matterColorRed) 0%, var(--matterColorBackgroundAnti) 100%); */
    opacity: 0.8;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* background: url('../../assets/Boldly BG.png') no-repeat; */
    /* background: url('../../assets/listingHeroMountainOverlay.svg') no-repeat right bottom; */
    background-origin: content-box;
    background-size: 80%;
    z-index: 0;

    @media (--viewportMedium) {
      background-size: 50%;
    }

    @media (--viewportLarge) {
      /* background-size: 40%; */
      padding-right: 45px;
      background-size: cover;

    }
  }

  /* Text positioning is done with paddings */
}

.heroFirst{
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 32px;
}

.heroContent {
  position: relative;
  /* top: 70px; */
  z-index: 1;
  display: flex;
  justify-content:space-between;

  margin: 0 24px 0 24px;
  /* border: 2px solid black; */


  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
    padding-bottom: 10px;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding-right: 36px;
    padding-left: 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding-right: 36px;
    padding-left: 36px;
  }
}

.heroMainTitle {
  margin-top: 0px;
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;
  font-size: 18px;

  color: var(--matterColorLight);
  margin: 0 0 0 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 0 0;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.coachingTitle {
  margin: 0;
  font-size: 2.7em;
  line-height: 56px;
  font-weight: 700;
}

.coachingSubTitle {
  font-size: 18px;
  line-height: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 300px;
  }
}

.redButton {
  composes: buttonColorsRed from global;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.buttonWrapper {
  /* display: grid;
  grid-gap: 10px;
  gap: 10px; */

  @media (--viewportMedium) {
    display: flex;
  }
}

.buttonWrapper .heroButton:nth-of-type(2n) {

  @media (--viewportMedium) {
    margin-left: 20px;
  }
}


.displayflexRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.btn1 {
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 250px;
  /* height: 10%; */
  font-size: 14px;
  /* margin-top: 100px; */
  background-color: #ffffff;
  font-weight: 700;
  color: #FC3E44;
  transition: all 150ms ease-out;
  margin-bottom: 10px;
  /* position: relative;
  top: 70px; */

}

.spaceTop{
  position: relative;
  top: 10px;
}

.btn1:hover {
  /* box-shadow: 8px 9px 6px 1px #00000096;
   transition: all 150ms ease-out; */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.15);
}

.btn1:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255, 255, 255, 0.9); */

}