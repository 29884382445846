@import '../../styles/customMediaQueries.css';

.root {
}

ul {
  margin-bottom: 0;
}

.addPeople {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.inputEmail {
  display: flex;
}

.removeEmail {
  margin-left: auto;
}

.actionButton {
  margin-left: 16px;
}

.actionButton,
.removeEmail {
  cursor: pointer;
  color: var(--marketplaceColor);
  font-size: 14px;
}

.input {
  flex: 1;
  & input,
  & div {
    font-size: 14px;
  }
}

.item {
  display: flex;
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.textCheckBox {
  composes: marketplaceListingAttributeFontStyles from global;
  font-size: 14px;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.submitButton {
  background: var(--matterColorRed);
  border-radius: 4px;
  margin-top: 100px;

  &:hover,
  &:focus {
    background: var(--matterColorRed);
  }
}

.submitButton.submitButtonInProgress {
  &:disabled,
  &:ready {
    background: var(--matterColorRed);
  }
}
