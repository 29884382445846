@import '../../../styles/customMediaQueries.css';

.diagram {
  width: 100%;
  position: relative;
  display: flex;
  gap: 60px;
  height: 600px;

  @media (--viewportSLarge) {
    flex-flow: column;
    gap: 32px;
    align-items: center;
    height: 400px;
  }
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

  .illustratorLeft {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .illustratorTop {
    position: absolute;
    top: 0;
    left: 50%;
  }

  .illustratorRight {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.description {
  position: absolute;
  top: 10px;
  left: 30%;

  @media (--viewportSLarge) {
    top: 20%;
    left: 10%;
  }
}

.ctaDescription {
  position: absolute;
  bottom: 20%;
  left: 60%;
  z-index: 2;

  @media (--viewportSLarge) {
    right: 10%;
    top: 20%;
  }
}

.foreground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.processContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 32px;

  @media (--viewportSLarge) {
    justify-items: center;
    flex-flow: column;
  }
}


.process {
  max-width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
  row-gap: 32px;
  align-items: flex-start;

  @media (--viewportSLarge) {
    flex-flow: row;
    align-items: center;
  }
}

.arrow {
  width: 30px;
  transform: rotate(90deg);
  transform-origin: center;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  p {
    width: fit-content;
    margin: 0;
    line-height: 1;
    text-align: center;
  }

  @media (--viewportSLarge) {
    width: 100%;
    justify-content: center;
    align-items: center;
    transform: none;
  }
}

.stepContainer {
  display: flex;
  align-items: center;
}

.step {
  border-radius: 100px;
  width: 120px;
  min-width: 120px;
  height: 72px;

  background-color: var(--marketplaceColor);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  text-align: center;
  padding: 8px;
}

.stepArrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 40px solid var(--matterColorColorful);
  border-top: 0;
  transform: rotate(90deg);
}