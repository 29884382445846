@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.isReportAvailable,
.boldlyArrange,
.briefingAndDebriefing,
.coacheesDirectManager,
.managersEmail {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rowWrapper {

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
  }
}

.dropdownField {
  margin-bottom: 40px;
}

.hasCompletedAssessment {
  margin-bottom: 40px;
  width: 100%;

  @media (--viewportMedium) {
    width: 55%;
  }

  @media (--viewportLarge) {
    width: 65%;
  }

  @media (--viewportLargeWithPaddings) {
    width: 55%;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.materialLink {
  width: fit-content;
}