@import '../../styles/customMediaQueries.css';

.root {}

.item {
  list-style-type: disc;
  margin-left: 30px;

  @media (--viewportMedium) {
    margin-left: 50px;
  }
}

.orderedListTitle {
  font-weight: var(--fontWeightBold);
}

.orderedList {
  padding: 0;
  
  @media (--viewportSmall) {
    column-count: 2;
  }
}

.orderedList .item {
  list-style-type: decimal;
}

.orderedListTitle,
.spacing {
  margin-bottom: 16px;
}