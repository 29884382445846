.root {
  & input[type='range'] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 6px;
    background: var(--greyC5Color);
    border-radius: 5px;
    background-image: linear-gradient(var(--blue7cColor), var(--blue7cColor));
    background-size: 0 100%;
    background-repeat: no-repeat;
    border-bottom: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--blue7cColor);
      cursor: ew-resize;
      transition: background 0.3s ease-in-out;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-track,
    &::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  & label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    line-height: 18px;
    color: var(--matterColorNeutrals);

    @media (--viewportMedium) {
      font-size: 12px;
    }
  }
}
