@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 33px;
  }
}

.content {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-right: 30px;
    margin-bottom: 35px;
    flex-basis: calc(33.33% - 20px);

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.iconBoxItem {
    background-color: #f5f5f5;
    padding: 20px;
}
.iconBoxTitle {
    font-weight: 700;
    font-size: 25px;
}
.iconBoxSpacer {
    height: 2px;
    width: 100%;
    background-color: #fc3e44;
    margin-top: 15px;
    margin-bottom: 20px;
}
.iconBoxImage {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #fc3e44;
}
.iconBoxHeading {
    display: flex;
    gap: 30px;
    align-items: center;
}
.iconBoxItem ul {
    list-style-type: disc;
}
.iconBoxItem ul, .iconBoxItem ol {
    margin-left: 0;
    margin-top: 10px;
    padding-left: 30px;
}

.iconBoxItem ul li, .iconBoxItem ol li {
    margin-bottom: 10px;
}

.iconBoxItem br {
    margin-bottom: 15px;
    display: block;
    content: "";
    line-height: 15px;
}

.iconBoxContainer > div:not(:last-child) {
  margin-bottom: 20px;
}
.iconBoxContainer > div:last-child {
  margin-bottom: 40px;
}

.linkText {
  composes: h2 from global;

  font-size: 18px;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.text {
  color: var(--marketplaceColor);
}

.subText,
.subTitle {
  white-space: pre-line;
  color: var(--matterColor);
}

.subText {
  font-size: 13px;
}

.showOriginalImage {
  object-fit: contain;
}

.showVideoThumbnail {
  object-fit: unset;
}

.cover {
  object-fit: cover;
}