@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.fieldDateInput {
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.submitButton {
  margin: auto auto 24px auto;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 25px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.divider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 22px 0 16px 0;
  }
}

.totalPriceRow {
  display: flex;
}

.totalPriceValue {
  margin-left: 200px;
}

.actionError {
  color: var(--failColor);
}