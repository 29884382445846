.root {
  /* box-shadow: 0 4px 10px 0 var(--skeletonBoxShadowColor); */
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ededed;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 300px;
    transform: translateX(-100%);
    background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #ededed;
    animation: shimmer 2.6s infinite;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(300%);
  }
}
