@import '../../styles/customMediaQueries.css';

.stickyModal {
  position: sticky;
  top: 100px;
}

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 40px 30px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  font-size: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h4 from global;
  color: var(--matterColor);
  width: 100%;
  text-align: end;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px 15px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  flex-direction: column;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 30px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: 5px 12px;
  margin: 0 auto;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  width: 100%;
  text-align: end;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.viewShortListButton,
.viewAgreementButton {
  width: 100%;
  margin-top: 20px;
  background-color: var(--matterColorColorful);
  font-weight: var(--fontWeightRegular);
  border-radius: 4px;

  &:hover, &:focus {
    background-color: var(--matterColorColorfulDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
  }
}

.viewShortListButton {
  margin: 0;
}

.customButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  border-radius: 5px;
  text-align: left;
  min-height: unset;
  height: 45px;

  @media (--viewportLarge) {
    margin-top: 0px;
    height: 65px;
    font-size: 18px;
  }
}

.agreementModal,
.modal {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;

  height: 100%;
  padding: 65px 30px 30px;

  @media (--viewportMedium) {
    flex-basis: 700px;
    flex-grow: 0;
    height: 80vh;

    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    border-bottom: 8px solid var(--marketplaceColor);
  }
}

.agreementModalContent {
  @media (--viewportMedium) {
    overflow-y: scroll;
  }
}

.modalCloseButton {
  composes: marketplaceModalCloseStyles from global;

  justify-content: flex-end;
  width: 100%;
  background-color: var(--matterColorLight);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;

  @media (--viewportMedium) {
    width: 100%;
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    width: 324px;
    padding: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  padding: 0 15px;
  margin-right: 15px;
  background-color: var(--marketplaceColorDark);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.redDark {
  background-color: var(--matterColorColorfulDark);
}

.redDarkColor {
  background-color: var(--matterColorRedDark);
}
.violetDark {
  background-color: var(--matterColorVioletDark);
}

.violetButton {
  background-color: var(--matterColorViolet);

  &:hover, &:focus {
    background-color: var(--matterColorVioletDark);
  }
}

.icon svg {
  width: 24px;
  height: 24px;
}

.iconSearch {
  transform: rotate(270deg) scale(1.6);
  stroke: var(--matterColorLight);
  stroke-width: 2px;
}

.icon .iconAddList {
  width: 35px;
  height: 35px;
}

.icon .iconSearch {
  width: 22px;
  height: 22px;
  margin: 0 3px;
}
.redButton {
  background-color: var(--authenticationLinkColor);

  &:hover,
  &:focus {
    background-color: var(--matterColorRedDark);
  }
}

.popupContent {
  position: absolute;
  bottom: -68px;
  z-index: 1;
  border-radius: 4px;

  &.redPopUp,
  &.violetPopUp {
    color: var(--matterColorLight);
    bottom: -52px;
  }

  &.redPopUp {
    background-color: var(--authenticationLinkColor);

    &:after {
      border-color: transparent var(--authenticationLinkColor) var(--authenticationLinkColor) transparent;
    }
  }

  &.violetPopUp {
    background-color: var(--matterColorViolet);

    &:after {
      border-color: transparent var(--matterColorViolet) var(--matterColorViolet) transparent;
    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & :last-child {
    margin-top: 10px;
  }
}

.bookingPanelFormModalHeading {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.addToCoachListSuccessTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.error {
  color: var(--failColor);
}

.addToCoachListSuccessLineGroup {
  composes: marketplaceModalParagraphStyles from global;
  margin: 60px 0;
}

.addIconContainer {
  background-image: linear-gradient(
    135deg,
    var(--matterColorFirstForAvatar) 0%,
    var(--matterColorSecondForAvatar) 100%
  );
  width: 100px;
  height: 100px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;

  @media (--viewportMedium) {
    margin: -20px auto 40px;
  }
}

.addIcon {
  width: 50px;
  height: 50px;
}

.link {
  color: var(--failColor);
  & :hover {
    text-decoration: underline;
    color: var(--failColor);
    cursor: pointer;
  }
}

.comma {
  color: var(--failColor);
}
