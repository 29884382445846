.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;

  font-size: 30px;
  line-height: 32px;
}
/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  margin: 34px 0 24px 0;
}

.mainContent {
  composes: marketplaceModalParagraphStyles from global;

  font-size: 15px;
  font-weight: var(--fontWeightRegular);
  margin: 24px 0;
}

.mainContentLink {
  color: var(--authenticationLinkColor);
}

.subContent {
  composes: marketplaceModalParagraphStyles from global;

  font-size: 12px;
  font-weight: var(--fontWeightRegular);
}

.subContentLink {
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;

  margin: 0;
  margin-top: 32px;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.error {
  color: var(--failColor);
}