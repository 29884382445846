/* .contentListed{
    border: 1px solid rgba(0, 0, 0, 0.093);
    border-radius: 5px;
    } */
    .textAdd{
        font-weight: 100 !important;
        font-size:medium;
        color: black;
    }
    .aspectW img{
        position: absolute;
        top: 6px;
        bottom: 0;
        left: 9px;
        right: 0;
        width: 95%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .lineTexts{
        text-align: start !important;
        padding: 14px 9px 0px;
        margin-top: 9px;

    }