@import '../../styles/customMediaQueries.css';

.panelTitle {
  margin-bottom: 0px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.container {
  width: 100%;
  height: 70vh;
  overflow: scroll;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px 0;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.textNote {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  font-size: 14px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 263px;
  }
}

.buttonSection {
  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
    gap: 45px;
  }
}
