@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* overflow-x: scroll; */
  opacity: 2.5;
  padding: 20px 20px;
  gap: 15px;

  @media (--viewportMedium) {
    justify-content: space-evenly;
    /* overflow-x: hidden; */
  }

  /* @media screen and (min-width:550px) and (max-width:760px) {
    flex-direction: column;
    justify-content: flex-start;
  } */
  @media screen and (max-width:760px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
  }
}

.container {
  margin-top: 70px;
  margin-bottom: 70px;
}

.container {
  width: 100%;
  /* Full width */
  padding-right: var(--bs-gutter-x, 0.75rem);
  /* Padding on right */
  padding-left: var(--bs-gutter-x, 0.75rem);
  /* Padding on left */
  margin-right: auto;
  /* Centering */
  margin-left: auto;
  /* Centering */
  max-width: 100%;
  /* Prevent overflow */
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .root {
    /* display: block; */
    /* flex-direction: column; */
    justify-content: space-evenly;
    overflow-x: hidden;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.jardinesLogo,
.pizzahutLogo,
.fisherLogo {}

.jardinesLogo {
  width: 120px;
}

.pizzahutLogo {
  width: 140px;
}

.fisherLogo {
  width: 140px;
}

.FirstLogoBanner {
  /* padding-bottom: 40px; */


}

@media screen and (max-width:760px) {
  .FirstLogoBanner {
    padding-bottom: 0;
  }
}

.FirstLogoBanner h1 {
  font-size: -webkit-xxx-large;
}